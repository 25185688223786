/*
 * Footer do site
 */

import React from 'react';
import './styles/footer.scss';
import SocialMedia from '../SocialMedia';
import logoAnaPng from '../../assets/img/logo-ana.png';
import logoAnaWebp from '../../assets/img/logo-ana.webp';

export default function Footer(){
  
  return (
    <footer>
      <a href="https://www.ana.gov.br/" title="Agência Nacional de Águas" className="ana" target="_blank" rel="noopener noreferrer">
        <img src={window.Modernizr.webp ? logoAnaWebp : logoAnaPng} alt="Agência Nacional de Águas" />
      </a>
      <SocialMedia />
    </footer>
  )
}