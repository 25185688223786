import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import ListItems from './containers/ListItems';
import Footer from './components/Footer';
const { detect } = require('detect-browser');

export default function SnirhApp(){
  const [items, setItems] = useState([]);
  const [viewport, setViewport] = useState('');

  function handleResize(e){
    const wWidth = window.innerWidth;

    // Define qual o viewport
    if( wWidth < 1024 )
      setViewport('smartphone');
    else if( wWidth >= 1024 && wWidth <= 1279 )
      setViewport('tablet');
    else
      setViewport('desktop');
  }

  useEffect(() => {
    const browser = detect();
    document.documentElement.classList.add(browser.name);
    
    handleResize();

    axios.get('/static/items.json')
      .then(function(response){
        setItems(response.data);
      });

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <>
      <Sidebar viewport={viewport} />
      <ListItems items={items} viewport={viewport} />
      {viewport === 'smartphone' && <Footer viewport={viewport} />}
    </>
  )
}