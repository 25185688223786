/*
 * Lista de itens
 */

import React, { useState, useEffect } from 'react';
import jQuery from 'jquery';
import './styles/listItems.scss';

function ListItems(props){
  const [noHover, setNoHover] = useState(false);
  const [itActive, setItActive] = useState('');

  useEffect(() => {

  }, []);

  function returnItems(){
    if(props.viewport === 'smartphone')
      return [...props.items].reverse();
    else
      return props.items;
  }

  function returnViewItemMobo(){
    if( noHover && itActive !== '' )
    {
      jQuery('html').addClass('mobile-item-open');

      return returnItems().map((it, index) => it.slug === itActive && <div key={index} className={'item mobo ' + it.slug}>
            <button type="button" className="bt-close" onClick={(e) => {setItActive(''); setNoHover(false);}} title="Fechar">Fechar</button>
            <h3 dangerouslySetInnerHTML={{__html: it.title}} />
            <h4 dangerouslySetInnerHTML={{__html: it.active.title}} />
            <div className="content" dangerouslySetInnerHTML={{__html: it.active.content}} />
            {!!it.active.links.items.length && <div className="links">
              <h5 dangerouslySetInnerHTML={{__html: it.active.links.title}} />
              <ul>
                {it.active.links.items.map((link, idx) => <li key={idx}>
                  <a href={link.url} title={link.text} target="_blank" rel="noopener noreferrer">{link.text}</a>
                </li>)}
              </ul>
            </div>}
            <div className="nav clear">
              {index > 0 && <div className={'prev ' + returnItems()[index - 1].slug} onClick={(e) => {setItActive(returnItems()[index - 1].slug); setNoHover(true); window.scrollTo(0, 0);}}>
                <p>Anterior</p>
                <h6 dangerouslySetInnerHTML={{__html: returnItems()[index - 1].title}} />
              </div>}
              {!!returnItems()[index + 1] && <div className={'next ' + returnItems()[index + 1].slug} onClick={(e) => {setItActive(returnItems()[index + 1].slug); setNoHover(true); window.scrollTo(0, 0);}}>
                <p>Próximo</p>
                <h6 dangerouslySetInnerHTML={{__html: returnItems()[index + 1].title}} />
              </div>}
            </div>
          </div>).filter(it => { return it});
    }else
    {
      jQuery('html').removeClass('mobile-item-open');
      return null;
    }
  }

  if(props.viewport === 'smartphone')
    return (
      <>
        <main id="listItems" className={'smartphone clear'}>
          <ul>
            {returnItems().map((it, index) => <li key={index} onClick={(e) => {setItActive(it.slug); setNoHover(true);}} className={'item ' + it.slug}>
              <h3 dangerouslySetInnerHTML={{__html: it.title}} />
            </li>)}
          </ul>
        </main>
        {returnViewItemMobo()}
      </>
    )
  else
    return (
      <main id="listItems" className={'desktop clear' + (noHover ? ' no-hover' : '') }>
        {returnItems().map((it, index) => <div key={index} className={'item ' + it.slug + ( itActive === it.slug ? ' active' : '' )}>
          <div className="init">
            <h3 dangerouslySetInnerHTML={{__html: it.title}} />
          </div>
          <div className="hover">
            <h3 dangerouslySetInnerHTML={{__html: it.title}} />
            <div className="summary" dangerouslySetInnerHTML={{__html: it.summary}} />
            <button type="button" onClick={(e) => {setItActive(it.slug); setNoHover(true);}}>{it.button}</button>
          </div>
          <div className="active">
            <button type="button" className="bt-close" onClick={(e) => {setItActive(''); setNoHover(false);}} title="Fechar">Fechar</button>
            <h3 dangerouslySetInnerHTML={{__html: it.active.title}} />
            <div className="content" dangerouslySetInnerHTML={{__html: it.active.content}} />
            {!!it.active.links.items.length && <div className="links">
              <h4 dangerouslySetInnerHTML={{__html: it.active.links.title}} />
              <ul>
                {it.active.links.items.map((link, idx) => <li key={idx}>
                  <a href={link.url} title={link.text} target="_blank" rel="noopener noreferrer">{link.text}</a>
                </li>)}
              </ul>
            </div>}
          </div>
        </div>)}
      </main>
    )
}

export default ListItems;