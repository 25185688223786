/*
 * Lista com redes sociais
 */

import React from 'react';
import './styles/socialMedia.scss';

export default function SocialMedia(){
  return (
    <ul className={'socialMedia'}>
      <li>
        <a href="https://www.youtube.com/channel/UCLLjIf0AjdwQAtp3hDmrhZQ" title="YouTube" className="yt" target="_blank" rel="noopener noreferrer">YouTube</a>
      </li>
      <li>
        <a href="https://instagram.com/anagovbr" title="Instagram" className="ig" target="_blank" rel="noopener noreferrer">Instagram</a>
      </li>
      <li>
        <a href="https://twitter.com/anagovbr" title="Twitter" className="tw" target="_blank" rel="noopener noreferrer">Twitter</a>
      </li>
      <li>
        <a href="https://facebook.com/anagovbr" title="Facebook" className="fb" target="_blank" rel="noopener noreferrer">Facebook</a>
      </li>
      <li>
        <a href="https://br.linkedin.com/company/anagovbr" title="LinkedIn" className="in" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </li>
      <li>
        <a href="#d" title="WhatsApp" className="wp" target="_blank" rel="noopener noreferrer">WhatsApp</a>
      </li>
      <li>
      <a href="mailto:portalsnirh@ana.gov.br" title="Email" className="email" target="_blank" rel="noopener noreferrer">Email</a>
      </li>
    </ul>
  )
}