/*
 * Sidebar do site
 */

import React, { useState } from 'react';
import SocialMedia from '../SocialMedia';
import './styles/sidebar.scss';
import logoAnaPng from '../../assets/img/logo-ana.png';
import logoAnaWebp from '../../assets/img/logo-ana.webp';

export default function Sidebar(props){
  const [open, setOpen] = useState(false);
  
  return (
    <section id="sidebar" className={open && props.viewport === 'smartphone' ? 'show-about' : ''}>
      {props.viewport === 'smartphone' && <button type="button" className={!open ? 'bt-open' : 'bt-close'} onClick={(e) => {setOpen(!open);}} title={!open ? 'Sobre' : 'Fechar'}>{!open ? '?' : 'Fechar'}</button>}
      <h1><a href="http://www.snirh.gov.br/portal/" target="_blank" rel="noopener noreferrer">Portal do <strong>SNIRH</strong></a></h1>
      <div className="infos clear">
        <h2>Sistema Nacional de Informações sobre Recursos Hídricos (SNIRH)</h2>
        <p>O SNIRH é a base para disponibilização das informações sobre águas no Brasil, contribuindo para a difusão do conhecimento sobre recursos hídricos.</p>
      </div>
      {props.viewport !== 'smartphone' && <div className="social clear">
        <a href="https://www.ana.gov.br/" title="Agência Nacional de Águas" className="ana" target="_blank" rel="noopener noreferrer">
          <img src={window.Modernizr.webp ? logoAnaWebp : logoAnaPng} alt="Agência Nacional de Águas" />
        </a>
        <SocialMedia />
      </div>}
    </section>
  )
}